import Glide, {Autoplay, Breakpoints, Controls, Swipe,} from "@glidejs/glide/dist/glide.modular.esm";

(($) => {
    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 400) {
                $("#backtotop")
                    .removeClass("opacity-0 invisible")
                    .addClass("opacity-100 visible");
            } else {
                $("#backtotop")
                    .removeClass("opacity-100 visible")
                    .addClass("opacity-0 invisible");
            }
        });

        $("#backtotop").click(function () {
            $("html, body").animate(
                {
                    scrollTop: "0px",
                },
                500
            );
        });

        //LIGHTGALLERY WORDPRESS
        $(".wp-block-gallery,.lightgallery")
            .lightGallery({
                download: false,
                selector: "a",
            })
            .on("onBeforeSlide.lg", function () {
                $(".lg-backdrop").css("opacity", "0.8");
                $(".lg-sub-html").css("opacity", "0");
            });

        //HEADER
        $('#menu-burger').click(function (){
           $('#mobile-menu').toggleClass('open');
        });
        //MENU SUB-MENU
        $('#mobile-menu .menu-item.menu-item-has-children').click(function (){
            if(window.matchMedia(`(max-width: 1280px)`).matches){
                $(this).toggleClass('open');
            }
        });

        //MODAL
        $('.modal--event').click(function (e) {
            e.preventDefault();
            var content = $(this).find('.modal--content').html();
            openModal(content);
        });
        $('.modal--bg, #modal .close').click(function () {
            closeModal();
        });
        function openModal(content) {
            $('#modal').find('.container').append(content);
            $('.modal--container').addClass('show');
            $('html, body').addClass('overflow-hidden');
        }
        function closeModal() {
            $('.modal--container').removeClass('show');
            $('html, body').removeClass('overflow-hidden');
            setTimeout(function () {
                $('#modal').find('.container').empty();
            }, 300);
        }

        //ACCORDIONS
        $('.accordion .accordion--label').click(function (){
            $(this).toggleClass('active');
            $(this).parent().find('.accordion--content').stop().slideToggle(300);
        });

        //SLIDER
        function GlideAutoHeight(Glide, Components, Events) {
            const Component = {
                mount() {
                    if (!Glide.settings.autoHeight) return;
                    Components.Html.track.style.transition = 'height 200ms ease-in-out';
                    this.updateTrackHeight();
                },

                updateTrackHeight() {
                    if (!Glide.settings.autoHeight) return;

                    setTimeout(() => {
                        const activeSlides = Components.Html.slides.filter((slide, index) => {
                            return (index >= Glide.index && index <= (Glide.index-1) + Glide.settings.perView);
                        });

                        const newMaxHeight = activeSlides.reduce((maxHeight, slide) => {
                            //console.log(maxHeight, slide.offsetHeight);
                            return Math.max(maxHeight, slide.offsetHeight);
                        }, 0);

                        const glideTrack = Components.Html.track;
                        if (newMaxHeight !== glideTrack.offsetHeight) {
                            glideTrack.style.height = `${newMaxHeight}px`;
                        }
                    }, 100);
                },
            };

            Events.on('run', () => {Component.updateTrackHeight();});
            Events.on('update', () => {Component.updateTrackHeight();});
            Events.on('resize', () => {Component.updateTrackHeight();});

            return Component;
        }

        $('.glide').each(function () {
            new Glide($(this)[0], {
                perView: 1,
                focusAt: "center",
                gap: 0,
                autoHeight: true,
            }).mount({Autoplay, Breakpoints, Controls, Swipe, GlideAutoHeight});
        });

        $('.glide-actus').each(function (){
            const options = {
                type: 'carousel',
                autoplay: 3000,
                hoverpause: true,
                perView: 3,
                gap: 0,
                breakpoints: {
                    1024: {
                        type: 'carousel',
                        perView: 2
                    },
                    640: {
                        type: 'carousel',
                        perView: 1
                    }
                }
            };
            if($(this).hasClass('not-desktop-slider')){
                options.type = 'slider';
            }

            new Glide($(this)[0], options).mount({Autoplay, Breakpoints, Controls, Swipe});
        });
    });
})(jQuery);